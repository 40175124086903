import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: null, // 用户信息
    isLogin: false, // 是否登录
    token: '', // token
    isMobile: false, // 是否移动端打开页面
    adminUserInfo: null, // 管理员信息
    isLoginAdmin: false, // 管理员是否登录
    adminToken: '', // 管理员token
  },
  getters: {
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
      // 存储到 sessionStorage 中
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    UPDATE_LOGIN_STATUS(state, status) {
      state.isLogin = status
      // 存储到 sessionStorage 中
      sessionStorage.setItem('isLogin', status)
    },
    SET_TOKEN_INFO(state, token) {
      state.token = token
      // 存储到 sessionStorage 中
      sessionStorage.setItem('token', token)
    },
    SET_MOBILE_STATUS(state, status) {
      state.isMobile = status
    },
    SET_ADMIN_USER_INFO(state, userInfo) {
      state.adminUserInfo = userInfo
      // 存储到 sessionStorage 中
      sessionStorage.setItem('adminUserInfo', JSON.stringify(userInfo))
    },
    ADMIN_UPDATE_LOGIN_STATUS(state, status) {
      state.isLoginAdmin = status
      // 存储到 sessionStorage 中
      sessionStorage.setItem('isLoginAdmin', status)
    },
    SET_ADMIN_TOKEN_INFO(state, token) {
      state.adminToken = token
      // 存储到 sessionStorage 中
      sessionStorage.setItem('adminToken', token)
    },
  },
  actions: {
  },
  modules: {
  }
})
