(function (doc, win) {
  let docEl = doc.documentElement
  let clientWidth
  let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
  let recalc = function () {
    clientWidth = docEl.clientWidth
    if (!clientWidth) return
    if (clientWidth > 680 && clientWidth < 1024) { // 适配iPad
      docEl.style.fontSize = 10 * (clientWidth / 480) + 'px'
    } else {
      docEl.style.fontSize = 10 * (clientWidth / 360) + 'px'
    }
  }
  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)