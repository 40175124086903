import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/common.less'
import './config/rem.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
// 注册所有图标
Object.keys(ElementPlusIconsVue).forEach((key) => {
  app.component(key, ElementPlusIconsVue[key]);
})

app.use(store).use(router).use(ElementPlus).mount('#app')


// 在应用初始化时从 sessionStorage 中恢复状态

const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
if (userInfo) {
  store.commit('SET_USER_INFO', userInfo)
}

const isLogin = sessionStorage.getItem('isLogin')
if (isLogin) {
  store.commit('UPDATE_LOGIN_STATUS', isLogin)
}

const token = sessionStorage.getItem('token')
if (token) {
  store.commit('SET_TOKEN_INFO', token)
}

const adminUserInfo = JSON.parse(sessionStorage.getItem('adminUserInfo'))
if (adminUserInfo) {
  store.commit('SET_ADMIN_USER_INFO', adminUserInfo)
}

const isLoginAdmin = sessionStorage.getItem('isLoginAdmin')
if (isLoginAdmin) {
  store.commit('ADMIN_UPDATE_LOGIN_STATUS', isLoginAdmin)
}

const adminToken = sessionStorage.getItem('adminToken')
if (adminToken) {
  store.commit('SET_ADMIN_TOKEN_INFO', adminToken)
}