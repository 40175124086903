import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
// import { ElMessage } from 'element-plus'

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/CommonPart.vue'),
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: () => import('../views/HomePage.vue')
      },
      {
        path: '/prodslist',
        name: 'ProdsList',
        component: () => import('../views/ProdsList.vue'),
        meta: { userAuth: false }
      },
      {
        path: '/proddetails',
        name: 'ProdDetails',
        component: () => import('../views/ProdDetails.vue'),
        meta: { userAuth: false }
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('../views/admin/AdminLogin.vue')
  },
  {
    path: '/admin/',
    name: '',
    component: () => import('../views/admin/CommonMenu.vue'),
    children: [
      {
        path: '/admin/',
        name: 'AdminHome',
        component: () => import('../views/admin/AdminHome.vue'),
        meta: { requiresAuth: true } // 添加 requiresAuth 元信息表示需要登录才能访问
      },
      {
        path: '/admin/shopbrowsing',
        name: 'ShopBrowsing',
        component: () => import('../views/admin/ShopBrowsing.vue'),
        meta: { requiresAuth: true } 
      },
      {
        path: '/admin/productbrowsing',
        name: 'ProductBrowsing',
        component: () => import('../views/admin/ProductBrowsing.vue'),
        meta: { requiresAuth: true } 
      },
      {
        path: '/admin/userinfo',
        name: 'UserInfo',
        component: () => import('../views/admin/UserInfo.vue'),
        meta: { requiresAuth: true } 
      },
      {
        path: '/admin/noticerelease',
        name: 'NoticeRelease',
        component: () => import('../views/admin/NoticeRelease.vue'),
        meta: { requiresAuth: true } 
      },
      {
        path: '/admin/email',
        name: 'EmailSend',
        component: () => import('../views/admin/EmailSend.vue'),
        meta: { requiresAuth: true } 
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // const isUserAuth = store.state.userInfo
  const isAuthenticated = store.state.isLoginAdmin;
  // 控制前端登录状态
  // if (to.matched.some(record => record.meta.userAuth) && !isUserAuth) {
  //   ElMessage('please log in first.')
  //   next('/');
  // } else 
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {// 控制管理员登录状态
    next('/admin/login');
  } else {
    next();
  }
});

export default router
