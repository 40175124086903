<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    name: 'App',
    data () {
      return {
      }
    },
    created () {
      this.detectDevice()
    },
    mounted () {
    },
    components: {
    },
    computed: {
    },
    methods: {
      detectDevice() {
        if (window.innerWidth < 680) {
          this.$store.commit('SET_MOBILE_STATUS', true)
        } else {
          this.$store.commit('SET_MOBILE_STATUS', false)
        }
      }
    },
    watch: {
    }
  }
</script>
<style lang="less">
</style>
